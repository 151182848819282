#root {
  background-color: #f5f5f5;
}
#top,
.table {
  background-color: #ffffff;
}
.back-to-top-button {
  background-color: #ddd;
  color: #999;
  border-color: #ccc;
}
.refresh-button {
  margin-right: 15px;
  background-color: #3466ad;
}
.table-container {
  position: relative;
}
.sticky-top th {
  position: relative;
}
.sticky-top th:after,
.sticky-top th:before {
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
.sticky-top th:after {
  border-bottom: 2px solid #dee2e6;
  bottom: -2px;
}
.sticky-top th:before {
  border-top: 1px solid #dee2e6;
  top: -1px;
}
.spinner-style {
  margin: auto;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.overlay {
  width: 100vh;
  height: 100vw;
  background-color: #777;
}

.form-input {
  margin-bottom: 25px !important;
}
.form-label {
  margin-bottom: 10px !important;
}
.employee-card {
  /* width: 125px !important; */
  border-radius: 0;
  margin-bottom: 20px;
}
.card-body {
  text-align: center;
  padding: 0.5rem 0;
}
.card-all-hours {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 30px;
}
.card-all-projects {
  color: white;
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 5px;
}
.card-all-internal-hours {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 18px;
}
.card-internal-hours {
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.card-first-name {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 15px;
  text-transform: uppercase;
}
.card-last-name {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}
.results {
  background-color: #3466ad;
  color: white;
  padding: 10px 0;
}
.results-num {
  color: #dfd074;
}
.last-updated {
  text-transform: uppercase;
  font-size: 12px;
}
.small-action {
  text-decoration: underline;
  color: #446eb3;
  cursor: pointer;
  font-size: 12px;
}
.filter-container {
  border-right: 2px solid white;
}
.filters {
  margin-top: 20px;
}
.summary-table {
  padding: 15px;
}
.avatar-circle {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.avatar-card {
  border-radius: 0;
  border: 0;
  padding: 20px;
  position: relative;
}
.avatar-card-projects {
  border-radius: 0;
  border: 0;
  padding: 30px;
}
.avatar-image-container {
  flex: 0 0 70px;
}
.table-header-name {
  margin: 0;
  font-size: 36px;
  font-weight: bold;
  line-height: 36px;
}
.summary-hours {
  color: white;
  text-align: center;
  font-size: 0.75rem;
  vertical-align: middle !important;
}
.summary-hours-black {
  text-align: center;
  font-size: 0.75rem;
  vertical-align: middle !important;
}
.center {
  text-align: center;
}
.table-header-week {
  font-size: 0.75rem;
  text-align: center;
}
.table-left-header {
  font-size: 0.75rem;
}
.employee-tasks-table {
  font-size: 0.9rem;
}
.card-grid {
  display: grid;
  align-items: center;
  gap: 0px 10px;
  grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
}

.bar-chart-container {
  display: grid;
  align-items: flex-end;
  gap: 0px 5px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: column;
  height: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  grid-row-gap: 1px;
}
.column-project-name {
  min-height: 15px;
  /* white-space: nowrap; */
  overflow: hidden;
  line-clamp: 3;
  text-overflow: ellipsis;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.6rem;
  text-align: left;
}
.planner-name {
  line-height: 36px;
  font-size: max(1.8vw, 22px);
  font-weight: bold;
  color: black;
}

.column-name-plus-hours {
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-column-gap: 5px;
  background-color: #aaa;
  font-size: 0.6rem;
  text-align: right;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-right: 5px;
  line-height: 12px;
}

.column-hours {
  /* align-self: flex-end; */
  /* padding-bottom: 5px; */
}
.yellow {
  background-color: #dfd074;
}
.red {
  background-color: #b31b1b;
}
.green {
  background-color: #44a03a;
}
.column-date {
  font-size: max(0.7vw, 10px);
  text-align: center;
  line-height: max(0.7vw, 10px);
  color: black;
  font-weight: bold;
}
.column-day {
  font-size: max(1.3vw, 16px);
  text-transform: uppercase;
  text-align: center;
  line-height: max(1.3vw, 16px);
  color: black;
  padding-bottom: 5px;
}
.column-total-hours {
  font-size: 1.5rem;
  text-align: center;
  padding: 10px;
  background-color: white;
}
/* .bar-chart-background {
  display: absolute;
  top: 0;
  left: 0;
} */
.bar-chart {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 160px 305px 46px;
  color: white;
  line-height: 14px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.bar-chart-wrapper {
  grid-area: 1 / 1 / 5 / 1;
}
.bar-chart-background-red {
  grid-area: 2 / 1 / 3 / 1;
  border-top: 2px solid #f2a6a6;
  /* background-color: #edf8ec; */
}
.bar-chart-empty {
  grid-area: 4 / 1 / 5 / 1;
}
.bar-chart-background-green {
  grid-area: 3 / 1 / 4 / 1;
  border-top: 2px solid #b9e4b4;
  /* background-color: #fbf6ea; */
}
.bar-chart-fr {
  grid-area: 1 / 1 / 2 / 1;
  /* background-color: #fce9e9; */
}
.bar-chart-first-column {
  height: 100%;
  background-color: white;
  display: grid;
  grid-template-rows: 1fr 50px 50px;
  align-items: flex-end;
}
.navbar-light .navbar-nav .nav-link:not(.active) {
  color: #007bff;
}
.navbar-light .navbar-nav .nav-link:not(.active) {
  color: #0056b3;
}
.nav-item {
  border-right: 1px solid #aaa;
  padding-left: 10px;
  padding-right: 10px;
}
.nav-item:last-child {
  border-right: none;
}
.mobile-nav {
  list-style: none;
  border-right: none;
}
.column-link {
  color: white;
  text-decoration: none;
}
.column-link:hover {
  color: white;
}
